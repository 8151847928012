// jQuery
window.$ = window.jQuery = require('jquery');

// Bootstrap
window.Popper = require('popper.js').default;
require('bootstrap');

// Moment.js
window.moment = require('moment');
require('moment/locale/it');

// Polyfills
if ("ActiveXObject" in window) { // IE11
    console.warn('Import core-js')
    require('core-js');
}

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});


window.timer = function(){
    if(typeof time === 'undefined') return false;
    var elem = $('.progress-semicircular').find('div');
    var elemMobile = $('.progress-circular').find('div');
    var elemLabel = $('.timeLabel');
    var iteration = 0;
    var loop = setInterval(function(){
        if($('.progress-semicircular').is(':visible') || $('.progress-circular').is(':visible')){
            iteration = parseFloat((iteration + 0.1).toFixed(1));
            var perc = Math.round((iteration/time/2*100) * 10) / 10;
            elem.css('background', 'conic-gradient(#ff8001 '+perc+'%, #D9D9D9 0deg)');
            elemMobile.css('background', 'conic-gradient(#ff8001 '+(perc*2)+'%, #000 0deg)');
            elem.data('time', iteration);

            //label timer
            var label = moment(iteration*1000).format('mm:ss');
            elemLabel.text(label);

            if(iteration >= time){
                clearInterval(loop);
                //endGame();
            }
        }
    },100);
};

$(function(){
    $('body').on('keypress paste', '[name="name"]', function(e) {
        var _this = this;
        var accept = "èéòçàùìABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz '";
        if(accept.indexOf(String.fromCharCode(e.which)) < 0) return false;
    });

    $('.share').click(function(){
        var _this = $(this);
        navigator.clipboard.writeText(_this.data('link'));
        _this.text(_this.data('text'));
        _this.css('padding-top', '3px');
    });
});
